<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    :hide-headers="$vuetify.breakpoint.smAndDown"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <tr>
        <td
          class="hidden-sm-and-down justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          {{ item.item.nome }}
          <v-icon small>
            mdi-pencil
          </v-icon>
        </td>
        <td
          class="hidden-md-and-up justify-left btnEditar cursorPointer"
          @click="editar(item.item)"
        >
          <div class="mb-2">
            <v-icon small>
              mdi-pencil
            </v-icon>
            <span class="nome-cliente ml-2">{{ item.item.nome }}</span>
          </div>
          <v-icon
            v-if="item.item.telefone1"
            small
          >
            mdi-phone-classic
          </v-icon>
          <span
            v-if="item.item.telefone1"
            class="ml-2"
          >{{ item.item.telefone1 }}</span>
          <br v-if="item.item.whatsapp">
          <v-tooltip
            v-if="item.item.whatsapp" 
            bottom
            style="margin: 0px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="v-btn--simple"
                icon
                color="green"
                :href="`http://wa.me/${item.item.whatsapp.trim()}`"
                target="blank"
                style="margin: 0px"
                v-on="on"
              >
                <v-icon 
                  style="margin: 0px"
                >
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('ClientesForm.abrir_whatsapp') }}</span>
          </v-tooltip>
          <span
            v-if="item.item.whatsapp"
            class="ml-2"
          >{{ item.item.whatsapp }}</span>
          <br v-if="item.item.email1">
          <v-icon
            v-if="item.item.email1"
            small
          >
            mdi-email-outline
          </v-icon>
          <span
            v-if="item.item.email1"
            class="ml-2"
          >{{ item.item.email1 }}</span>
        </td>
        <td class="hidden-sm-and-down justify-center">
          {{ item.item.telefone1 }}
        </td>
        <td class="hidden-sm-and-down justify-center">
          <v-tooltip 
            bottom
            style="margin: 0px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="v-btn--simple"
                icon
                color="green"
                :href="`http://wa.me/${item.item.whatsapp.trim()}`"
                target="blank"
                style="margin: 0px"
                v-on="on"
              >
                <v-icon 
                  style="margin: 0px"
                >
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('ClientesForm.abrir_whatsapp') }}</span>
          </v-tooltip>
          {{ item.item.whatsapp }}
        </td>
        <td class="hidden-sm-and-down justify-center">
          {{ item.item.email1 }}
        </td>
        <td class="hidden-sm-and-down justify-left">
          {{ item.item.cpfOuCnpj }}
        </td>
        <td v-if="!idEmpresaCliente">
          <span v-if="item.item.EmpresaCliente !== null">
            {{ item.item.EmpresaCliente.nome }}
          </span>
        </td>
      </tr>
    </template>
  </crud-list>
</template>

<script>

  import ClienteService from '@/services/ClientesService'
  import { mapState } from 'vuex'
  import colunaEmpresaCliente from '@/utils/EmpresaClienteUtils'

  export default {
    data: () => ({
      totalRegistros: 0,
      pagination: {},
      items: [],
      loading: false,
      itemRemover: {}
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPesquisa: function () {
        return this.$i18n.t('ClientesForm.tituloPesquisa')
      },
      headers: function () {
        const retorno = [
          {
            text: this.$i18n.t('ClientesForm.nome'),
            value: 'nome'
          },
          {
            text: this.$i18n.t('ClientesForm.telefone1'),
            value: 'telefone1',
            class: 'hidden-sm-and-down',
            sortable: false
          },
          {
            text: this.$i18n.t('ClientesForm.whatsapp'),
            value: 'whatsapp',
            class: 'hidden-sm-and-down',
            sortable: false
          },
          {
            text: this.$i18n.t('ClientesForm.email1'),
            value: 'telefone1',
            class: 'hidden-sm-and-down',
            sortable: false
          },
          {
            text: this.$i18n.t('ClientesForm.cpfOuCnpj'),
            value: 'telefone1',
            class: 'hidden-sm-and-down',
            sortable: false
          }
        ]

        if (!this.idEmpresaCliente) {
          retorno.push(colunaEmpresaCliente(this.$i18n.t('EmpresaClientesForm.tituloPagina')))
        }

        return retorno
      }
    },
    watch: {
      idEmpresaCliente (val) {
        if(val !== 0){
          this.buscar()
        }
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', true)
      this.$root._events.pesquisa = []
      this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    methods: {
      efetuarPesquisa (pesquisa) {
        this.loading = true
        const param = {
          nome: pesquisa,
          idEmpresaCliente: this.idEmpresaCliente
        }
        ClienteService.findByParam(param, this.pagination)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      buscar () {
        if(!this.idEmpresaCliente || this.idEmpresaCliente === 0){
          return
        }
        this.loading = true
        ClienteService.findAll(this.pagination, this.idEmpresaCliente)
          .then((dados) => {
            if (dados && dados.data && dados.data.rows) {
              this.items = dados.data.rows
            }
            if (dados && dados.data) {
              this.totalRegistros = dados.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ': \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          path: '/clienteForm'
        })
      },
      editar (item) {
        if (item && item.id) {
          const url = '/clienteForm/' + item.id
          this.$router.push(url)
        }
      }
    }
  }
</script>

<style>
.nome-cliente {
  font-weight: bold;
}
</style>
