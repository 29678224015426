var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('crud-list',{attrs:{"titulo-pesquisa":_vm.tituloPesquisa,"headers":_vm.headers,"items":_vm.items,"total-registros":_vm.totalRegistros,"paginacao":_vm.pagination,"hide-headers":_vm.$vuetify.breakpoint.smAndDown},on:{"update:paginacao":function($event){_vm.pagination=$event},"buscar-event":_vm.buscar,"novo-event":_vm.novo},scopedSlots:_vm._u([{key:"items",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"hidden-sm-and-down justify-left btnEditar cursorPointer",on:{"click":function($event){return _vm.editar(item.item)}}},[_vm._v(" "+_vm._s(item.item.nome)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('td',{staticClass:"hidden-md-and-up justify-left btnEditar cursorPointer",on:{"click":function($event){return _vm.editar(item.item)}}},[_c('div',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_c('span',{staticClass:"nome-cliente ml-2"},[_vm._v(_vm._s(item.item.nome))])],1),(item.item.telefone1)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-phone-classic ")]):_vm._e(),(item.item.telefone1)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.item.telefone1))]):_vm._e(),(item.item.whatsapp)?_c('br'):_vm._e(),(item.item.whatsapp)?_c('v-tooltip',{staticStyle:{"margin":"0px"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"v-btn--simple",staticStyle:{"margin":"0px"},attrs:{"icon":"","color":"green","href":("http://wa.me/" + (item.item.whatsapp.trim())),"target":"blank"}},on),[_c('v-icon',{staticStyle:{"margin":"0px"}},[_vm._v(" mdi-whatsapp ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ClientesForm.abrir_whatsapp')))])]):_vm._e(),(item.item.whatsapp)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.item.whatsapp))]):_vm._e(),(item.item.email1)?_c('br'):_vm._e(),(item.item.email1)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-email-outline ")]):_vm._e(),(item.item.email1)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.item.email1))]):_vm._e()],1),_c('td',{staticClass:"hidden-sm-and-down justify-center"},[_vm._v(" "+_vm._s(item.item.telefone1)+" ")]),_c('td',{staticClass:"hidden-sm-and-down justify-center"},[_c('v-tooltip',{staticStyle:{"margin":"0px"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"v-btn--simple",staticStyle:{"margin":"0px"},attrs:{"icon":"","color":"green","href":("http://wa.me/" + (item.item.whatsapp.trim())),"target":"blank"}},on),[_c('v-icon',{staticStyle:{"margin":"0px"}},[_vm._v(" mdi-whatsapp ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ClientesForm.abrir_whatsapp')))])]),_vm._v(" "+_vm._s(item.item.whatsapp)+" ")],1),_c('td',{staticClass:"hidden-sm-and-down justify-center"},[_vm._v(" "+_vm._s(item.item.email1)+" ")]),_c('td',{staticClass:"hidden-sm-and-down justify-left"},[_vm._v(" "+_vm._s(item.item.cpfOuCnpj)+" ")]),(!_vm.idEmpresaCliente)?_c('td',[(item.item.EmpresaCliente !== null)?_c('span',[_vm._v(" "+_vm._s(item.item.EmpresaCliente.nome)+" ")]):_vm._e()]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }